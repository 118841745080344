import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import Styles from "./nes.module.css";
import ecom from "../../assets/images/home/ecom.png";
import fm from "../../assets/images/home/fm.png";
import payroll from "../../assets/images/home/payroll.png";
import pos from "../../assets/images/home/pos.png";
import inv from "../../assets/images/home/inv.png";
import im from "../../assets/images/home/im.png";
import kb from "../../assets/images/home/kb.png";
import crm from "../../assets/images/home/crm.png";
import hr from "../../assets/images/home/hr.png";
import attendance from "../../assets/images/home/attendance.png";

function Nes() {
    const [isEcomRectVisible, setIsEcomRectVisible] = useState(false);
    const [isInventoryRectVisible, setIsInventoryRectVisible] = useState(false);
    const [isPosRectVisible, setIsPosRectVisible] = useState(false);
    const [isCrmRectVisible, setIsCrmRectVisible] = useState(false);
    const [isPayrollRectVisible, setIsPayrollRectVisible] = useState(false);
    const [isHrRectVisible, setIsHrRectVisible] = useState(false);
    const [isAttendanceRectVisible, setIsAttendanceRectVisible] = useState(false);
    const [isKbRectVisible, setIsKbRectVisible] = useState(false);
    const [isImRectVisible, setIsImRectVisible] = useState(false);
    const [isFmRectVisible, setIsFmRectVisible] = useState(false);
    const nesRef = useRef(null); 

    

    const handleMouseEnterEcom = () => {
        setIsEcomRectVisible(true);
        
    };

    const handleMouseLeaveEcom = () => {
        setIsEcomRectVisible(false);
    };

    const handleMouseEnterInventory = () => {
        setIsInventoryRectVisible(true);
    };

    const handleMouseLeaveInventory = () => {
        setIsInventoryRectVisible(false);
    };

    const handleMouseEnterPos = () => {
        setIsPosRectVisible(true);
    };

    const handleMouseLeavePos = () => {
        setIsPosRectVisible(false);
    };

    const handleMouseEnterCrm = () => {
        setIsCrmRectVisible(true);
    };

    const handleMouseLeaveCrm = () => {
        setIsCrmRectVisible(false);
    };

    const handleMouseEnterPayroll = () => {
        setIsPayrollRectVisible(true);
    };

    const handleMouseLeavePayroll = () => {
        setIsPayrollRectVisible(false);
    };

    const handleMouseEnterHr = () => {
        setIsHrRectVisible(true);
    };

    const handleMouseLeaveHr = () => {
        setIsHrRectVisible(false);
    };

    const handleMouseEnterAttendance = () => {
        setIsAttendanceRectVisible(true);
    };

    const handleMouseLeaveAttendance = () => {
        setIsAttendanceRectVisible(false);
    };

    const handleMouseEnterKb = () => {
        setIsKbRectVisible(true);
    };

    const handleMouseLeaveKb = () => {
        setIsKbRectVisible(false);
    };

    const handleMouseEnterIm = () => {
        setIsImRectVisible(true);
    };

    const handleMouseLeaveIm = () => {
        setIsImRectVisible(false);
    };

    const handleMouseEnterFm = () => {
        setIsFmRectVisible(true);
    };

    const handleMouseLeaveFm = () => {
        setIsFmRectVisible(false);
    };

    return (
        <div className={Styles.container}>
            {/* E-Commerce Section */}
            <div
                className={`${Styles.ecom} ${isEcomRectVisible ? Styles.ecomHovered : ''} `}
                onMouseEnter={handleMouseEnterEcom}
                onMouseLeave={handleMouseLeaveEcom}
                >
                <Typography className={Styles.smallRectText}>E-Commerce</Typography>
            </div>

            {/* Inventory Section */}
            <div
                className={`${Styles.inventory} ${isInventoryRectVisible ? Styles.ecomHovered : ''}`}
                onMouseEnter={handleMouseEnterInventory}
                onMouseLeave={handleMouseLeaveInventory}
            >
                <Typography className={Styles.smallRectText}>
                    Inventory
                </Typography>
            </div>

            {/* POS Section */}
            <div
                className={`${Styles.pos} ${isPosRectVisible ? Styles.ecomHovered : ''}`}
                onMouseEnter={handleMouseEnterPos}
                onMouseLeave={handleMouseLeavePos}
            >
                <Typography className={Styles.smallRectText}>
                    POS
                </Typography>
            </div>

            {/* CRM Section */}
            <div
                className={`${Styles.crm} ${isCrmRectVisible ? Styles.ecomHovered : ''}`}
                onMouseEnter={handleMouseEnterCrm}
                onMouseLeave={handleMouseLeaveCrm}
            >
                <Typography className={Styles.smallRectText}>
                    CRM
                </Typography>
            </div>

            {/* Payroll Section */}
            <div
                className={`${Styles.payroll} ${isPayrollRectVisible ? Styles.ecomHovered : ''}`}
                onMouseEnter={handleMouseEnterPayroll}
                onMouseLeave={handleMouseLeavePayroll}
            >
                <Typography className={Styles.smallRectText}>
                    Payroll
                </Typography>
            </div>

            {/* HR Section */}
            <div
                className={`${Styles.hr} ${isHrRectVisible ? Styles.ecomHovered : ''}`}
                onMouseEnter={handleMouseEnterHr}
                onMouseLeave={handleMouseLeaveHr}
            >
                <Typography className={Styles.smallRectText}>
                    HR
                </Typography>
            </div>

            {/* Attendance Section */}
            <div
                className={`${Styles.attendance} ${isAttendanceRectVisible ? Styles.ecomHovered : ''}`}
                onMouseEnter={handleMouseEnterAttendance}
                onMouseLeave={handleMouseLeaveAttendance}
            >
                <Typography className={Styles.smallRectText}>
                    Attendance
                </Typography>
            </div>

            {/* Knowledge Base Section */}
            <div
                className={`${Styles.kb} ${isKbRectVisible ? Styles.ecomHovered : ''}`}
                onMouseEnter={handleMouseEnterKb}
                onMouseLeave={handleMouseLeaveKb}
            >
                <Typography className={Styles.smallRectText}>
                    Knowledge Base
                </Typography>
            </div>

            {/* Issue Management Section */}
            <div
                className={`${Styles.im} ${isImRectVisible ? Styles.ecomHovered : ''}`}
                onMouseEnter={handleMouseEnterIm}
                onMouseLeave={handleMouseLeaveIm}
            >
                <Typography className={Styles.smallRectText}>
                    Issue Management
                </Typography>
            </div>

            {/* Finance Management Section */}
            <div
                className={`${Styles.fm} ${isFmRectVisible ? Styles.ecomHovered : ''}`}
                onMouseEnter={handleMouseEnterFm}
                onMouseLeave={handleMouseLeaveFm}
            >
                <Typography className={Styles.smallRectText}>
                    Finance Management
                </Typography>
            </div>

            

            <div className={`${Styles.rectangle} ${isEcomRectVisible ? Styles.hideRectangle : ''}`}>
                <div className={Styles.textWrap}>
                    <Typography className={Styles.text}>
                        {/* {displayText} */}  NES
                    </Typography>
                </div>
            </div>




            <div className={`${Styles.ecomRect} ${isEcomRectVisible ? '' : Styles.hidden}`}>         
                <Typography className={Styles.textHover}>
                    <h3>E-Commerce</h3>
                    <h6>Build your E-Commerce platform from us</h6>
                </Typography> 
                <img className={Styles.image} src={ecom} alt="Men" />         
            </div>

            <div className={`${Styles.inventoryRect} ${isInventoryRectVisible ? '' : Styles.hidden}`}>           
                <Typography className={Styles.textHover}>
                    <h3>Inventory</h3>
                    <h6>Build your Inventory platform from us</h6>
                </Typography> 
                <img className={Styles.image} src={inv} alt="Men" />            
            </div>

             <div className={`${Styles.posRect} ${isPosRectVisible ? '' : Styles.hidden}`}>               
                <Typography className={Styles.textHover}>
                    <h3>POS</h3>
                    <h6>Build your POS platform from us</h6>
                </Typography> 
                <img className={Styles.image} src={pos} alt="Men" />          
            </div>

            <div className={`${Styles.crmRect} ${isCrmRectVisible ? '' : Styles.hidden}`}>              
                <Typography className={Styles.textHover}>
                    <h3>CRM</h3>
                    <h6>Build your CRM platform from us</h6>
                </Typography> 
                <img className={Styles.image} src={crm} alt="Men" />
            </div>

            <div className={`${Styles.payrollRect} ${isPayrollRectVisible ? '' : Styles.hidden}`}>              
                <Typography className={Styles.textHover}>
                    <h3>Payroll</h3>
                    <h6>Build your Payroll platform from us</h6>
                </Typography> 
                <img className={Styles.image} src={payroll} alt="Men" />
            </div>

            <div className={`${Styles.hrRect} ${isHrRectVisible ? '' : Styles.hidden}`}>              
                <Typography className={Styles.textHover}>
                    <h3>HR</h3>
                    <h6>Build your HR platform from us</h6>
                </Typography> 
                <img className={Styles.image} src={hr} alt="Men" />
            </div>

            <div className={`${Styles.attendanceRect} ${isAttendanceRectVisible ? '' : Styles.hidden}`}>              
                <Typography className={Styles.textHover}>
                    <h3>Attendance</h3>
                    <h6>Build your Attendance platform from us</h6>
                </Typography> 
                <img className={Styles.image} src={attendance} alt="Men" />
            </div>

            <div className={`${Styles.kbRect} ${isKbRectVisible ? '' : Styles.hidden}`}>              
                <Typography className={Styles.textHover}>
                    <h3>Knowledge Base</h3>
                    <h6>Build your Knowledge Base platform from us</h6>
                </Typography> 
                <img className={Styles.image} src={kb} alt="Men" />
            </div>

            <div className={`${Styles.imRect} ${isImRectVisible ? '' : Styles.hidden}`}>              
                <Typography className={Styles.textHover}>
                    <h3>Issue Management</h3>
                    <h6>Build your Issue Management platform from us</h6>
                </Typography> 
                <img className={Styles.image} src={im} alt="Men" />
            </div>

            <div className={`${Styles.fmRect} ${isFmRectVisible ? '' : Styles.hidden}`}>              
                <Typography className={Styles.textHover}>
                    <h3>Finance Management</h3>
                    <h6>Build your Finance Management platform from us</h6>
                </Typography> 
                <img className={Styles.image} src={fm} alt="Men" />
            </div>


        </div>
    );
}

export default Nes;