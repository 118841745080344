import React from 'react'
import ProductsPageIntro from "./productsPageIntro";
import { Button, Container, Grid, Typography } from '@mui/material';
import Styles from './productsIntro.module.css';
import image2 from '../../assets/images/productImages/Rectangleqqq 470.png';

const ESERVICE = () => {

  return (
    <Container>
      <div className={Styles.Page}>
        <div className={Styles.PageContainer}>
          <Typography className={Styles.PageTitle}>E-Services</Typography>
          <img className={Styles.PageImage} src={image2} alt="E-Com" />
          <Typography className={Styles.PageDescription}>
          Welcome to N-ACH E Service, To market and sell your services online!
          </Typography>
          <Typography className={Styles.PageSubtitle}>
          Welcome to N-ACH advanced E-Services System, the all-in-one solution that empowers individuals and businesses to streamline their processes, enhance efficiency, and embrace the digital era.
          <br /><br />
          Embark on a journey of efficiency and convenience with our E-Services System. Embrace the future of digital services and elevate your daily operations. Join us today and experience the transformation of how services are delivered and accessed!
          <br /><br />
          Feel free to tailor this product description according to the specific features, benefits, and branding of your E-Services System.
          </Typography>
          <Typography className={Styles.PageKeyFeatures}>KEY FEATURES</Typography>
        </div>
      </div>  

        <div className={Styles.descriptionContainer}>
          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Seamless Online Experience:</Typography>
              <Typography className={Styles.descriptionText}>
              Access a comprehensive suite of e-services conveniently from anywhere, at any time. Our platform offers a user-friendly interface that ensures a seamless online experience for all users.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Customizable Service Packages:</Typography>
              <Typography className={Styles.descriptionText}>
              Tailor the services to match your specific needs. Choose from a range of customizable packages designed to cater to individuals, small businesses, or large enterprises.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Secure and Encrypted Transactions:</Typography>
              <Typography className={Styles.descriptionText}>
              Trust is our priority. Rest assured that all transactions and interactions within our system are securely encrypted, maintaining the confidentiality and integrity of your data.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Efficient Document Management:</Typography>
              <Typography className={Styles.descriptionText}>
              Say goodbye to paperwork. Our E-Services System allows you to upload, store, and manage your documents digitally. Access them with ease and never worry about misplacing important information again.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Automated Notifications and Reminders:</Typography>
              <Typography className={Styles.descriptionText}>
              Stay on top of your tasks with our automated notification system. Receive timely alerts and reminders for appointments, deadlines, payments, and more, ensuring you never miss a beat.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Diverse Service Offerings:</Typography>
              <Typography className={Styles.descriptionText}>
              Explore a wide range of e-services, including e-payments, online bookings, virtual consultations, e-learning, and more. Our platform caters to various needs, saving you time and effort.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>24/7 Customer Support:</Typography>
              <Typography className={Styles.descriptionText}>
              Rest easy knowing that our dedicated customer support team is available around the clock to assist you. Whether you have inquiries or encounter challenges, we're here to help.
              </Typography>
            </div>
          </div>

        </div>
        <div style={{marginTop:"20px",marginBottom:"10px"}}>
        <ProductsPageIntro />
      </div>
      
    </Container>
    
  );
}

export default ESERVICE;