import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { MainLayout } from './layouts/mainlayout';
import AboutUs from './pages/aboutUs';
import Contact from './pages/contact';
import Home from './pages/home';
import Products from './pages/products';
import POS from './components/productsPages/pos';
import CRM from './components/productsPages/crm';
import STOCK from './components/productsPages/stock';
import ECOM from './components/productsPages/ecom';
import ECOMLITE from './components/productsPages/ecomlite';
import HR from './components/productsPages/hr';
import PAYROLL from './components/productsPages/payroll';
import ESERVICE from './components/productsPages/eservices';
import IMS from './components/productsPages/ims';
import Services from './pages/services';
import BPM from './components/servicePages/bpm';
import WebDesign from './components/servicePages/webDesign';
import Trailor from './components/servicePages/trailor';

function PageRoutes() {
  return (
    <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route index element={<Home /> } />
      <Route path="aboutUs" element={<AboutUs />} />
      <Route path="contact" element={<Contact />} />
      <Route path="products">
        <Route index element={<Products /> } />
        <Route path="ecom" element={<ECOM />} />
        <Route path="ecomlite" element={<ECOMLITE />} />
        <Route path="stock" element={<STOCK />} />
        <Route path="crm" element={<CRM />} />
        <Route path="hr" element={<HR />} />
        <Route path="payroll" element={<PAYROLL />} />
        <Route path="ims" element={<IMS />} />
        <Route path="eservice" element={<ESERVICE />} />
        <Route path="pos" element={<POS />} />
      </Route>
      <Route path="services">
        <Route index element={<Services /> } />
        <Route path="web-design" element={<WebDesign />} />
        <Route path="tailor-made-software-solutions" element={<Trailor />} />
        <Route path="bpm" element={<BPM />} />
      </Route>
    </Route>
  </Routes>
  )
}
export default PageRoutes;