import React from 'react'
import ProductsPageIntro from "./productsPageIntro";
import { Button, Container, Grid, Typography } from '@mui/material';
import Styles from './productsIntro.module.css';
import image2 from '../../assets/images/productImages/E-BizLite.png';

const ECOMLITE = () => {

  return (
    <Container>
      <div className={Styles.Page}>
        <div className={Styles.PageContainer}>
          <Typography className={Styles.PageTitle}>N-ACH E-COM LITE</Typography>
          <img className={Styles.PageImage} src={image2} alt="E-Com" />
          <Typography className={Styles.PageDescription}>
            Welcome to N-ACH E-COMLITE, well your products and services on your stunning web site.
          </Typography>
          <Typography className={Styles.PageSubtitle}>
          Welcome to our specialized e-commerce platform.
          </Typography>
          <Typography className={Styles.PageKeyFeatures}>KEY FEATURES</Typography>
        </div>
      </div>  

        <div className={Styles.descriptionContainer}>
          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Stunning website</Typography>
              <Typography className={Styles.descriptionText}>
              The design of your website plays a crucial role in attracting visitors. Use a clean and visually appealing layout, with a color scheme that matches your brand. Ensure your site is responsive, so it looks great on all devices.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>High-Quality Visuals</Typography>
              <Typography className={Styles.descriptionText}>
              Use high-resolution images and videos to showcase your products or services. Professional visuals are essential to make a positive first impression.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>User-Friendly Navigation</Typography>
              <Typography className={Styles.descriptionText}>
              Organize your website with a logical menu structure and easy-to-use navigation. Make it simple for visitors to find what they're looking for.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Search Engine Optimization (SEO)</Typography>
              <Typography className={Styles.descriptionText}>
              Optimize your website for search engines to improve its visibility. Use relevant keywords, meta descriptions, and alt tags for images.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Create Packages / Sets </Typography>
              <Typography className={Styles.descriptionText}>
              Combine multiple products or services and create a unique packages or set for your clients. 
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Analytics and Reporting</Typography>
              <Typography className={Styles.descriptionText}>
              Access actionable insights and reports that provide valuable data on customer behaviors, sales performance, marketing ROI, and more. Use this data to make informed business decisions and refine your strategies.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Customer Support</Typography>
              <Typography className={Styles.descriptionText}>
              Provide excellent customer support through various channels (email, chat, phone) to assist users with inquiries and issues.
              </Typography>
            </div>
          </div>

        </div>
        <div style={{marginTop:"20px",marginBottom:"10px"}}>
        <ProductsPageIntro />
      </div>
    </Container>
  );
}

export default ECOMLITE;