import React, { useState } from 'react';
import image from "../../assets/images/aboutUs/videoScreen.png";
import { Typography } from '@mui/material';
import Styles from "./introVid.module.css"

const IntroVid = () => {

  const [view, setView] = useState(false)

  return (
    <div>
      <Typography className={Styles.topic}>Our Introduction</Typography>
      <div className={Styles.imgContainer}>
        {view ?
          <iframe
            className={Styles.video}
            src={`https://www.youtube.com/embed/FbH8aPxSRSM`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        :
          <img onClick={() => setView(true)} src={image} alt="Introduction Image" className={Styles.img}/>
        }
      </div>
    </div>
  );
};

export default IntroVid;
