// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videoSet_grid__FZfO2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 3rem;
  width: 100%;
  height: 100%;
}

.videoSet_big__-KLYa {
  grid-column: span 2;
  grid-row: span 2;
  border: 1px solid #FFCECE;
  border-radius: 10px;
  border: 1px solid #FFCECE;
  background: linear-gradient(258deg, rgba(255, 241, 245, 0.77) 0%, rgba(255, 202, 202, 0.00) 100%);
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoSet_small__QuLrc {
  border-radius: 10px;
  flex-direction: column;
  border: 1px solid #FFCECE;
  background: linear-gradient(180deg, rgba(255, 181, 128, 0.19) 19.46%, rgba(253, 255, 155, 0.22) 100%);
  height: 300px;
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.videoSet_centerImage__1DoTl{
  align-items: center;
}

.videoSet_text__QIgCv{
  font-size: 30px;
  margin-bottom: 30px;
  align-items: center;
}


@media (max-width: 770px) {
  small {
    font-size: 20px;
  }
  .videoSet_big__-KLYa {
    height: 400px;
}
}

@media (min-width: 771px) {

  .videoSet_text__QIgCv{
    font-size: 40px;
  }
  .videoSet_centerImage__1DoTl{
   margin-bottom: -15px;
  }
}






`, "",{"version":3,"sources":["webpack://./src/components/home/videoSet.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,kCAAkC;EAClC,cAAc;EACd,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,iGAAiG;EACjG,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,yBAAyB;EACzB,qGAAqG;EACrG,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,mBAAmB;AACrB;;;AAGA;EACE;IACE,eAAe;EACjB;EACA;IACE,aAAa;AACjB;AACA;;AAEA;;EAEE;IACE,eAAe;EACjB;EACA;GACC,oBAAoB;EACrB;AACF","sourcesContent":[".grid {\r\n  display: grid;\r\n  grid-template-columns: repeat(2, 1fr);\r\n  grid-template-rows: repeat(2, 1fr);\r\n  grid-gap: 3rem;\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.big {\r\n  grid-column: span 2;\r\n  grid-row: span 2;\r\n  border: 1px solid #FFCECE;\r\n  border-radius: 10px;\r\n  border: 1px solid #FFCECE;\r\n  background: linear-gradient(258deg, rgba(255, 241, 245, 0.77) 0%, rgba(255, 202, 202, 0.00) 100%);\r\n  height: 700px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.small {\r\n  border-radius: 10px;\r\n  flex-direction: column;\r\n  border: 1px solid #FFCECE;\r\n  background: linear-gradient(180deg, rgba(255, 181, 128, 0.19) 19.46%, rgba(253, 255, 155, 0.22) 100%);\r\n  height: 300px;\r\n  display: flex;\r\n  justify-content: center; \r\n  align-items: center; \r\n}\r\n\r\n.centerImage{\r\n  align-items: center;\r\n}\r\n\r\n.text{\r\n  font-size: 30px;\r\n  margin-bottom: 30px;\r\n  align-items: center;\r\n}\r\n\r\n\r\n@media (max-width: 770px) {\r\n  small {\r\n    font-size: 20px;\r\n  }\r\n  .big {\r\n    height: 400px;\r\n}\r\n}\r\n\r\n@media (min-width: 771px) {\r\n\r\n  .text{\r\n    font-size: 40px;\r\n  }\r\n  .centerImage{\r\n   margin-bottom: -15px;\r\n  }\r\n}\r\n\r\n\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `videoSet_grid__FZfO2`,
	"big": `videoSet_big__-KLYa`,
	"small": `videoSet_small__QuLrc`,
	"centerImage": `videoSet_centerImage__1DoTl`,
	"text": `videoSet_text__QIgCv`
};
export default ___CSS_LOADER_EXPORT___;
