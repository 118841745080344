import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Styles from "./ourProducts.module.css";
import image1 from "../../assets/images/Smart_Biz.jpg";
import image2 from "../../assets/images/E-ComLite.jpg";
import image3 from "../../assets/images/CRM.png";
import image4 from "../../assets/images/POS.png";
import image5 from "../../assets/images/HRM.png";
import image6 from "../../assets/images/Payroll.png";
import image7 from "../../assets/images/IMS.png";
import image8 from "../../assets/images/E-Service.png";
import image9 from "../../assets/images/Inventory.png";

import { useNavigate } from 'react-router-dom';

const products = [
    {
        image: image1,
        subtopic: "Smart Biz",
        link: "/products/ecom",
        text: "Shopping meets convenience and variety",
    },
    {
        image: image2,
        subtopic: "Ecommerce Lite",
        link: "/products/ecomlite",
        text: "Stunning website with a E-Commerce",
    },
    {
        image: image3,
        subtopic: "CRM",
        link: "/products/crm",
        text: "Advanced Customer Relationship Management System",
    },
    {
        image: image4,
        subtopic: "Inventory and Monitoring",
        link: "/products/stock",
        text: "Manage  your stocks easily",
    },
    {
        image: image5,
        subtopic: "HRM",
        link: "/products/hr",
        text: "Advanced human resouce system with 360 view of your staff",
    },
    {
        image: image6,
        subtopic: "Payroll",
        link: "/products/payroll",
        text: "User friendly payment system your staff",
    },
    {
        image: image7,
        subtopic: "Issue Management",
        link: "/products/ims",
        text: "Cutting-edge Issue Ianagement System",
    },
    {
        image: image8,
        subtopic: "EService",
        link: "/products/eservice",
        text: "One system for various issues, complaints and suggestions",
    },
    {
        image: image9,
        subtopic: "Point of Sale",
        link: "/products/pos",
        text: "Advance online point of sale for your shop",
    },
];

function OurProducts() {

    const navigate = useNavigate()

    return (
        <div className={`${Styles.outerContainer}`}>
            <Typography className={Styles.topic}>Our Products</Typography>

            <Grid container spacing={3} justifyContent="left">
                {products.map((product, index) => (
                    
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <div className={Styles.productContainer}onClick={() => navigate(product.link)} variant="contained" color="primary">
                            <img src={product.image} alt={`Product ${index + 1}`} className={Styles.productImage} />
                            <div className={Styles.textContainer}>
                                <Typography className={Styles.productSubtopic}>{product.subtopic}</Typography>
                                <Typography className={Styles.productText}>{product.text}</Typography>
                                {/* <Button className={Styles.button} onClick={() => navigate(product.link)} variant="contained" color="primary">
                                    More Info
                                </Button> */}
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>

        </div>
    );
}

export default OurProducts;


