import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import Styles from './servicesIntro.module.css';
import image1 from '../../assets/images/productImages/productImage.png';
import image2 from '../../assets/images/productImages/Rectanglewe 470.png';
import { useNavigate } from 'react-router-dom';

const ServiceIntro = () => {

  const navigate = useNavigate()

  return (
    <div>
      <div className={Styles.outerContainer}>
        <Grid container>
          <Grid item xs={12} md={6} className={Styles.textContainer}>
            <div>
              <Typography className={Styles.topic}>Services</Typography>
              <Typography className={Styles.description}>
                We have put together a special combination of strategy, marketing, design, and digital expertise for your Need for ACHievement.
              </Typography>
              <Button onClick={() => navigate('/contact')} variant="contained" color="primary">
                Let's Talk
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={Styles.mainImage} src={image1} alt="Product" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ServiceIntro;
