import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#BE0002",
      contrastText: "#fff"
    },
    // secondary: {
      // main: '#6667ab',
      // contrastText: "#fff"
    // },
    text: {
      primary: "#414141",
    }
  },
  typography: {
    fontFamily: ['Verdana', 'Geneva', 'Tahoma', 'sans-serif'],
    button: {
      textTransform: "none"
    }
  },
});

export default theme;