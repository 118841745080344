import React from 'react'
import {Container, Typography } from '@mui/material';
import Styles from './servicesIntro.module.css';
import image2 from '../../assets/images/productImages/Rectanglewe 470.png';
import ServiceIntro from './serviceIntro';

const WebDesign = () => {

    return (
      <Container> 
        <div className={Styles.Page}>
          <div className={Styles.PageContainer}>
            <Typography className={Styles.PageTitle}>Web Site Design & Develop</Typography>
            <img className={Styles.PageImage} src={image2} alt="E-Com" />
            <Typography className={Styles.PageDescription}>
            Website Designing: Crafting Digital Experiences for Success.
            </Typography>
            
            <Typography className={Styles.PageSubtitle}>
            At our core, we are the architects of captivating digital landscapes. Our website designing services are meticulously tailored to embody your brand's essence while ensuring an exceptional user experience. Through a perfect fusion of creativity, functionality, and cutting-edge technology, we bring your unique vision to life.
            </Typography>
          </div>
        </div>  

          <div className={Styles.descriptionContainer}>
            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionText}>
                Our approach begins with understanding your brand, its values, and target audience. Armed with this insight, we embark on a journey to design a visually stunning and intuitive website that not only engages visitors but also converts them into loyal customers. From striking layouts to seamless navigation, every element is crafted with precision to leave a lasting impression.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionText}>
                Our expert team of designers is well-versed in the latest trends and industry best practices. We ensure that your website not only meets but exceeds modern design standards, making it responsive across various devices and platforms. By incorporating innovative design elements, interactive features, and compelling content, we captivate your audience and drive them towards your desired goals.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionText}>
                Join us in transforming your online presence into a compelling digital masterpiece, where creativity meets functionality, and success is just a click away.
                </Typography>
              </div>
            </div>
          </div>
          <div style={{marginTop:"20px",marginBottom:"10px"}}>
          <ServiceIntro />
        </div>
      </Container>
    );
  }

export default WebDesign;