import React from 'react'
import ProductsPageIntro from "./productsPageIntro";
import { Button, Container, Grid, Typography } from '@mui/material';
import Styles from './productsIntro.module.css';
import image2 from '../../assets/images/productImages/Rectangleqqq 470.png';

const CRM = () => {

  return (
    <Container> 
      <div className={Styles.Page}>
        <div className={Styles.PageContainer}>
          <Typography className={Styles.PageTitle}>N-ACH CRM</Typography>
          <img className={Styles.PageImage} src={image2} alt="E-Com" />
          <Typography className={Styles.PageDescription}>
          Welcome to our advanced Customer Relationship Management (CRM) System.
          </Typography>
          <Typography className={Styles.PageSubtitle}>
          N-ACH customer relationship management (CRM) System—an innovative and intuitive platform designed to revolutionize the way you manage customer interactions and enhance your business relationships.
          </Typography>
          <Typography className={Styles.PageKeyFeatures}>KEY FEATURES</Typography>
        </div>
      </div>  

        <div className={Styles.descriptionContainer}>
          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>360-Degree Customer View</Typography>
              <Typography className={Styles.descriptionText}>
              Gain a comprehensive understanding of your customers with a centralized database that consolidates all customer information, interactions, and transactions. Access a complete view of customer history to personalize interactions and drive meaningful engagement.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Marketing Campaigns and Email Integration</Typography>
              <Typography className={Styles.descriptionText}>
              Launch targeted marketing campaigns and monitor their effectiveness directly within the CRM. Integrate email marketing seamlessly, enabling you to manage and track campaigns, measure engagement, and adjust strategies for optimal results.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Contact and Lead Management</Typography>
              <Typography className={Styles.descriptionText}>
              Streamline your contact and lead management processes. Capture and organize leads effortlessly, nurturing them through the sales pipeline to conversion. Seamlessly manage contacts and their associated details for targeted communication.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Customer Support and Ticketing</Typography>
              <Typography className={Styles.descriptionText}>
              Deliver exceptional customer support by tracking and managing customer inquiries, issues, and tickets. Assign, prioritize, and resolve customer requests promptly, enhancing customer satisfaction and loyalty.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Sales Automation and Pipeline Management</Typography>
              <Typography className={Styles.descriptionText}>
              Optimize your sales process with our powerful automation tools. Automate repetitive tasks, track deals through the sales pipeline, set reminders, and analyze sales performance to drive revenue growth.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Analytics and Reporting</Typography>
              <Typography className={Styles.descriptionText}>
              Access actionable insights and reports that provide valuable data on customer behaviors, sales performance, marketing ROI, and more. Use this data to make informed business decisions and refine your strategies.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Customization and Scalability</Typography>
              <Typography className={Styles.descriptionText}>
              Tailor the CRM system to fit your business needs. Customize fields, workflows, and modules to match your unique requirements. Our system grows with you, easily scaling to accommodate your business growth.
              </Typography>
            </div>
          </div>

        </div>
        <div style={{marginTop:"20px",marginBottom:"10px"}}>
        <ProductsPageIntro />
      </div>
    </Container>
  );
}

export default CRM;