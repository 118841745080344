import React from 'react'
import ProductsPageIntro from "./productsPageIntro";
import {Container, Typography } from '@mui/material';
import Styles from './productsIntro.module.css';
import image2 from '../../assets/images/productImages/E-Biz.png';
import bullet from '../../assets/images/productImages/adjust.png';

const ECOM = () => {

    return (
      <Container>
        <div className={Styles.Page}>
          <div className={Styles.PageContainer}>
            <Typography className={Styles.PageTitle}>N-ACH Smart Biz</Typography>
            <img className={Styles.PageImage} src={image2} alt="E-Com" />
            <Typography className={Styles.PageDescription}>
              Welcome to N-ACH Smart Biz, where shopping meets convenience and variety!
            </Typography>
            <Typography className={Styles.PageSubtitle}>
              Discover a seamless online shopping experience like never before with our state-of-the-art e-commerce platform.
              Here, we strive to redefine the way you shop, offering an extensive range of products that cater to every need and desire.
            </Typography>
            <Typography className={Styles.PageKeyFeatures}>KEY FEATURES</Typography>
          </div>
        </div>  

          <div className={Styles.descriptionContainer}>
            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Diverse Product Selection</Typography>
                <Typography className={Styles.descriptionText}>
                  Explore a vast array of products across multiple categories, from electronics and fashion to home decor and more. We have carefully curated each collection to ensure you find exactly what you're looking for.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Promotions and Discounts</Typography>
                <Typography className={Styles.descriptionText}>
                Avail exciting discounts, seasonal offers, and promotions to make your shopping experience even more delightful. We believe in giving you the best value for your money.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>User-Friendly Interface</Typography>
                <Typography className={Styles.descriptionText}>
                Our intuitive and easy-to-navigate platform allows you to browse and shop effortlessly. Enjoy a smooth, enjoyable shopping journey from the comfort of your home or on the go.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Efficient Delivery</Typography>
                <Typography className={Styles.descriptionText}>
                We strive for speedy and reliable deliveries, ensuring your purchases reach you in no time. Choose your preferred shipping options and track your orders with ease.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Secure Transactions</Typography>
                <Typography className={Styles.descriptionText}>
                Your security is our priority. Rest assured that every transaction on our platform is safe and protected. We employ advanced security measures to keep your personal and financial information secure at all times.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Customer Support</Typography>
                <Typography className={Styles.descriptionText}>
                Our dedicated customer support team is here to assist you at every step of the way. Whether you have questions about products, orders, or need assistance, we're always just a message away.
                </Typography>
              </div>
            </div>

          </div>
          <div style={{marginTop:"20px",marginBottom:"10px"}}>
          <ProductsPageIntro />
        </div>
      </Container>
    );
  }

export default ECOM;