import React from 'react';
import Styles from "./containerTwo.module.css";
import redMan from "../../assets/images/aboutUs/redMan.png";
import { Typography } from '@mui/material';

const ContainerTwo = () => {
  return (
    <div>
      <div className={Styles.redBoxTop}>
        <Typography className={Styles.title}>We leave egos at the door.</Typography>
        <Typography className={Styles.description}>
          We challenge ideas and offer candid criticism, but we also emphasize the points on which we agree. We will challenge things in order to produce better work, but never merely out of curiosity. There isn't time for that.
        </Typography>
      </div>
      <div className={Styles.imageContainer}>
        <img src={redMan} alt="Image" className={Styles.image} />
      </div>
      <div className={Styles.redBoxBottom}>
        <Typography className={Styles.title}>We support one another</Typography>
        <Typography className={Styles.description}>
          Our days are spent constructing sites and strengthening one another. An opportunity to interact, work together, and recognize our clients and coworkers arises with each new project. We're in this together and for the long haul.
        </Typography>
      </div>
    </div>
  );
};

export default ContainerTwo;
