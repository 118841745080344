import React from 'react'
import ProductsPageIntro from "./productsPageIntro";
import { Button, Container, Grid, Typography } from '@mui/material';
import Styles from './productsIntro.module.css';
import image2 from '../../assets/images/productImages/Rectangleqqq 470.png';

const IMS = () => {

  return (
    <Container>
      <div className={Styles.Page}>
        <div className={Styles.PageContainer}>
          <Typography className={Styles.PageTitle}>Issue Management System</Typography>
          <img className={Styles.PageImage} src={image2} alt="E-Com" />
          <Typography className={Styles.PageDescription}>
            Welcome to N-ACH Issue Management System.
          </Typography>
          <Typography className={Styles.PageSubtitle}>
            Welcome to our Issue Management System—an intelligent and robust solution designed to streamline issue tracking, resolution, and enhance overall operational efficiency.
          </Typography>
          <Typography className={Styles.PageKeyFeatures}>KEY FEATURES</Typography>
        </div>
      </div>

      <div className={Styles.descriptionContainer}>
        <div className={Styles.descriptionItem}>
          <span className={Styles.bullet}></span>
          <div>
            <Typography className={Styles.descriptionTopic}>Effortless Issue Tracking:</Typography>
            <Typography className={Styles.descriptionText}>
              Streamline issue reporting and monitoring with our intuitive platform. Log issues, assign tasks, and track progress seamlessly, ensuring transparency and accountability throughout the resolution process.
            </Typography>
          </div>
        </div>

        <div className={Styles.descriptionItem}>
          <span className={Styles.bullet}></span>
          <div>
            <Typography className={Styles.descriptionTopic}>Categorization and Prioritization:</Typography>
            <Typography className={Styles.descriptionText}>
              Classify and prioritize issues based on their impact and urgency. Our system allows for categorization, enabling you to focus on critical issues and allocate resources efficiently.
            </Typography>
          </div>
        </div>

        <div className={Styles.descriptionItem}>
          <span className={Styles.bullet}></span>
          <div>
            <Typography className={Styles.descriptionTopic}>Collaborative Workspace:</Typography>
            <Typography className={Styles.descriptionText}>
              Foster collaboration and communication among teams. Our system provides a centralized workspace for team members to discuss issues, share insights, and work collectively towards effective resolutions.
            </Typography>
          </div>
        </div>

        <div className={Styles.descriptionItem}>
          <span className={Styles.bullet}></span>
          <div>
            <Typography className={Styles.descriptionTopic}>Real-Time Updates and Notifications:</Typography>
            <Typography className={Styles.descriptionText}>
              Stay informed in real-time with automated notifications and updates. Receive alerts on issue status changes, task assignments, and important milestones, ensuring timely actions and follow-ups.
            </Typography>
          </div>
        </div>

        <div className={Styles.descriptionItem}>
          <span className={Styles.bullet}></span>
          <div>
            <Typography className={Styles.descriptionTopic}>Comprehensive Reporting and Analytics:t</Typography>
            <Typography className={Styles.descriptionText}>
              Gain valuable insights into issue trends, resolution times, and team performance through detailed reports and analytics. Utilize this data to enhance processes, improve response times, and optimize workflows.
            </Typography>
          </div>
        </div>

        <div className={Styles.descriptionItem}>
          <span className={Styles.bullet}></span>
          <div>
            <Typography className={Styles.descriptionTopic}>Secure Data Handling:</Typography>
            <Typography className={Styles.descriptionText}>
              Rest easy knowing that sensitive issue data is handled with utmost security. Our system ensures data integrity and confidentiality, adhering to industry best practices and compliance requirements.
            </Typography>
          </div>
        </div>

        <div className={Styles.descriptionItem}>
          <span className={Styles.bullet}></span>
          <div>
            <Typography className={Styles.descriptionTopic}>Scalable and Customizable:</Typography>
            <Typography className={Styles.descriptionText}>
              Customize the system to match your organizational needs. Tailor fields, workflows, and notifications to align with your unique issue management processes. As your requirements evolve, our system scales effortlessly to accommodate growth.
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <Typography className={Styles.PageContainer}>
          Empower your teams and streamline your issue resolution process with our Issue Management System. Drive operational excellence, enhance customer satisfaction, and maintain a robust and efficient issue resolution framework. Join our community of satisfied users and experience the difference today!
          <br /><br />
          Feel free to adapt and customize this product description to match the specific features and benefits of your Issue Management System.
        </Typography>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "10px" }}>
        <ProductsPageIntro />
      </div>
    </Container>
  );
}

export default IMS;