import React from 'react';
import Styles from "./containerOne.module.css";
import building from "../../assets/images/aboutUs/building.png";
import { Typography } from '@mui/material';

const ContainerOne = () => {
  return (
    <div>
      <div className={Styles.imageContainer}>
        <img src={building} alt="Image" className={Styles.image} />
      </div>
      <div className={Styles.redBoxTop}>
        <Typography className={Styles.title}>We have a strong passion.</Typography>
        <Typography className={Styles.description}>
        We enjoy learning, particularly from one another. We are always improving our abilities in design, development, and marketing as well as learning how to use new platforms and features. Because lifelong learners are the best learners.
        </Typography>
      </div>
      <div className={Styles.redBoxBottom}>
        <Typography className={Styles.title}>We enjoy learning.</Typography>
        <Typography className={Styles.description}>
          The boundaries between "work" and "play" aren't always obvious. We are all connected and motivated by our curiosity and creativity. Staying in your comfort zone is never a good idea in our opinion.
        </Typography>
      </div>
    </div>
  );
};

export default ContainerOne;
