// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ourStory_outerContainer__x9S2L {
    margin-left: 4%;
    margin-right: 4%;
}

.ourStory_topic__5RSYP {
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.ourStory_description__7tTYs {
    width: 100%;
    font-size: 22px;
    text-align: justify;
    margin-top: 4%;
    margin-left: 8%;
}

/* .imageGrid img {
    max-width: 100%; 
    height: auto; 
    margin-left: 4%;
    margin-top: 4%;
} */

@media (max-width: 770px) {
    .ourStory_image__bPWS1{
        height: 16rem;
        width: 16rem;
    }

    .ourStory_description__7tTYs{
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/aboutUs/ourStory.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,eAAe;AACnB;;AAEA;;;;;GAKG;;AAEH;IACI;QACI,aAAa;QACb,YAAY;IAChB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".outerContainer {\r\n    margin-left: 4%;\r\n    margin-right: 4%;\r\n}\r\n\r\n.topic {\r\n    font-size: 3rem;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    text-align: center;\r\n    margin: 0;\r\n}\r\n\r\n.description {\r\n    width: 100%;\r\n    font-size: 22px;\r\n    text-align: justify;\r\n    margin-top: 4%;\r\n    margin-left: 8%;\r\n}\r\n\r\n/* .imageGrid img {\r\n    max-width: 100%; \r\n    height: auto; \r\n    margin-left: 4%;\r\n    margin-top: 4%;\r\n} */\r\n\r\n@media (max-width: 770px) {\r\n    .image{\r\n        height: 16rem;\r\n        width: 16rem;\r\n    }\r\n\r\n    .description{\r\n        width: 100%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `ourStory_outerContainer__x9S2L`,
	"topic": `ourStory_topic__5RSYP`,
	"description": `ourStory_description__7tTYs`,
	"image": `ourStory_image__bPWS1`
};
export default ___CSS_LOADER_EXPORT___;
