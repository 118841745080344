import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Styles from "./services.module.css";
import image from "../../assets/images/home/fluent-mdl2_world.png";
import VectorSVG from './vectorSVG';


export default function Services () {
    return (
      <div className={Styles.web}>
        <div className={Styles.overlap}>
          <div className={Styles.overlapGroup}>
            <div className={Styles.vector}>
              <VectorSVG />
            </div>
            <div className={Styles.textWrapper}>Web Design</div>
            <p className={Styles.websiteDesigning}>
              Website designing is the process of
              <br />
              creating visually appealing and
              <br />
              functional websites that engage users
              <br />
              and effectively convey a message.
            </p>
          </div>
          <img className={Styles.fluentWorld} alt="Fluent world" src={image} />
        </div>
      </div>
    );
  };

