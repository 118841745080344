import React from 'react'
import ProductsPageIntro from "./productsPageIntro";
import { Button, Container, Grid, Typography } from '@mui/material';
import Styles from './productsIntro.module.css';
import image2 from '../../assets/images/productImages/Rectanglewqqqq 470.png';

const STOCK = () => {

  return (
    <Container>
      <div className={Styles.Page}>
        <div className={Styles.PageContainer}>
          <Typography className={Styles.PageTitle}>Stocks and warehouse monitoring</Typography>
          <img className={Styles.PageImage} src={image2} alt="E-Com" />
          <Typography className={Styles.PageDescription}>
            Welcome to N-ACH Stock Monitoring System
          </Typography>
          <Typography className={Styles.PageSubtitle}>
          Introducing our cutting-edge Stock Monitoring System: a powerful solution designed to revolutionize how you manage and track your inventory.
          </Typography>
          <Typography className={Styles.PageKeyFeatures}>KEY FEATURES</Typography>
        </div>
      </div>  

      <div className={Styles.descriptionContainer}>
            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Real-Time Inventory Tracking:</Typography>
                <Typography className={Styles.descriptionText}>
                Stay informed with up-to-the-minute insights into your stock levels. Our system provides real-time monitoring, enabling you to make informed decisions on restocking, sales strategies, and more.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Barcode and QR Code Integration</Typography>
                <Typography className={Styles.descriptionText}>
                Simplify stock management with seamless barcode and QR code integration. Effortlessly scan items, update quantities, and track movements, saving time and reducing manual errors.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Comprehensive Reporting</Typography>
                <Typography className={Styles.descriptionText}>
                Access detailed reports and analytics to assess stock movement, trends, and inventory turnover. Use this data to optimize stocking levels, reduce holding costs, and improve overall efficiency.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Multi-Location Support</Typography>
                <Typography className={Styles.descriptionText}>
                Manage stock across multiple locations seamlessly. Whether you have one warehouse or many, our system ensures you have a consolidated view of your entire inventory network.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Automated Alerts and Notifications</Typography>
                <Typography className={Styles.descriptionText}>
                Set customized alerts for low stock, expiring items, or any other parameter critical to your business. Receive instant notifications, ensuring timely actions to prevent stockouts and overstock situations.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>User-Friendly Interface</Typography>
                <Typography className={Styles.descriptionText}>
                Our intuitive platform is designed for ease of use. Navigate effortlessly through the system, view inventory data at a glance, and perform actions with just a few clicks.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionTopic}>Customizable for Your Needs</Typography>
                <Typography className={Styles.descriptionText}>
                Tailor the system to fit your unique business requirements. Customize fields, categories, and notifications to match your industry and operational preferences.
                </Typography>
              </div>
            </div>
        </div>
        <div style={{marginTop:"20px",marginBottom:"10px"}}>
        <ProductsPageIntro />
      </div>
    </Container>
  );
}

export default STOCK;