import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Styles from "./intro.module.css";
import image from "../../assets/images/aboutUs/eagle.jpg";
import { useNavigate } from 'react-router-dom';

const Intro = () => {

  const navigate = useNavigate()

  return (
    <div className={Styles.outerContainer}>
        <img className={Styles.mainImage} src={image} alt="Your Alt Text"></img>
            <div className={Styles.textContainer}>
              <Typography className={Styles.topic}>About Us</Typography>
              <Typography className={Styles.description}>We have put together a special combination of strategy, marketing, design, and digital expertise for your Need for Achievement.</Typography>
              <Button variant='contained' color='primary'>Let's Talk</Button>
            </div>
            
    </div>
  )
}

export default Intro;
