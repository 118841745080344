import React from "react";
import IntroVid from '../aboutUs/introVid';
import { Button, Grid, Typography } from '@mui/material';
import Styles from "./videoSet.module.css";
import image3 from "../../assets/images/home/Youtube.png";
import intro from "../../assets/images/home/videoScreen.png";

export default function VideoSet() {

  const youtubeURLLogin = "https://youtu.be/YzK5LFunJRs";
  const youtubeURLCart = "https://youtu.be/c9nb8V0JrtI";
  const youtubeURLView = "https://youtu.be/BWHb4jNvc-k";
  const youtubeURLSearch = "https://youtu.be/4pbpfsgsBSM";

  return (
    <div className={Styles.grid}>
      <div className={Styles.big}>
        <IntroVid />
      </div>
      {/* <div className={Styles.small}>
        <div className={Styles.centerContent}>
          <Typography className={Styles.text}>Login Function</Typography>
          <a href={youtubeURLLogin} target="_blank" rel="noopener noreferrer">
            <img src={image3} alt="YouTube" className={Styles.centerImage} />
          </a>
        </div>
      </div>
      <div className={Styles.small}>
        <div className={Styles.centerContent}>
          <Typography className={Styles.text}>Cart Functions</Typography>
          <a href={youtubeURLCart} target="_blank" rel="noopener noreferrer">
            <img src={image3} alt="YouTube" className={Styles.centerImage} />
          </a>
        </div>
      </div>
      <div className={Styles.small}>
        <div className={Styles.centerContent}>
          <Typography className={Styles.text}>View Items</Typography>
          <a href={youtubeURLView} target="_blank" rel="noopener noreferrer">
            <img src={image3} alt="YouTube" className={Styles.centerImage} />
          </a>
        </div>
      </div>
      <div className={Styles.small}>
        <div className={Styles.centerContent}>
          <Typography className={Styles.text}>Search Bar</Typography>
          <a href={youtubeURLSearch} target="_blank" rel="noopener noreferrer">
            <img src={image3} alt="YouTube" className={Styles.centerImage} />
          </a>
        </div>
      </div> */}
    </div>
  );
}
