import React from 'react'
import ProductsPageIntro from "./productsPageIntro";
import { Button, Container, Grid, Typography } from '@mui/material';
import Styles from './productsIntro.module.css';
import image2 from '../../assets/images/productImages/Rectangleqqq 470.png';

const PAYROLL = () => {

  return (
    <Container>
      <div className={Styles.Page}>
        <div className={Styles.PageContainer}>
          <Typography className={Styles.PageTitle}>PAYROLL</Typography>
          <img className={Styles.PageImage} src={image2} alt="E-Com" />
          <Typography className={Styles.PageDescription}>
            Welcome to N-ACH Payroll system ! 
          </Typography>
          <Typography className={Styles.PageSubtitle}>
          Introducing our Online Payroll System—an all-in-one, secure, and efficient solution to simplify your payroll management and streamline your HR processes.
          </Typography>
          <Typography className={Styles.PageKeyFeatures}>KEY FEATURES</Typography>
        </div>
      </div>  

        <div className={Styles.descriptionContainer}>
          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Automated Payroll Processing:</Typography>
              <Typography className={Styles.descriptionText}>
              Say goodbye to manual calculations and paperwork. Our Online Payroll System automates the entire payroll process, from calculating salaries to generating pay slips, saving you valuable time and reducing errors.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Tax Compliance and Reporting:</Typography>
              <Typography className={Styles.descriptionText}>
              Ensure compliance with tax regulations effortlessly. Our system stays updated with the latest tax laws and helps generate accurate tax reports and filings, providing you peace of mind during tax seasons.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Employee Self-Service Portal:</Typography>
              <Typography className={Styles.descriptionText}>
              Empower your employees with a self-service portal. They can access their payroll information, view pay stubs, and download relevant tax forms, promoting transparency and reducing HR inquiries.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Time and Attendance Integration:</Typography>
              <Typography className={Styles.descriptionText}>
              Seamlessly integrate time and attendance data into the payroll system for precise hourly wage calculations. Effortlessly track employee work hours and streamline the payroll process further.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Multi-Payment Options:</Typography>
              <Typography className={Styles.descriptionText}>
              Support various payment methods such as direct deposit, checks, and electronic transfers. Accommodate your employees' preferences while ensuring timely and accurate payroll disbursements.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Data Security and Confidentiality:</Typography>
              <Typography className={Styles.descriptionText}>
              Rest assured, your sensitive payroll data is handled with utmost security. Our Online Payroll System employs advanced encryption techniques to safeguard your data and maintain confidentiality.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Comprehensive Reporting and Analytics:</Typography>
              <Typography className={Styles.descriptionText}>
              Gain valuable insights into your payroll data with our robust reporting and analytics tools. Analyze payroll trends, expenses, and employee costs to make informed decisions for your business.
              </Typography>
            </div>
          </div>

        <div>
          <Typography className={Styles.PageSubtitle}>
            Transform your payroll operations with our Online Payroll System. Simplify payroll processes, reduce administrative burdens, and focus on what matters most—growing your business. Join the ranks of satisfied users and experience effortless payroll management today!
          </Typography>
        </div>

        <div>
          <Typography className={Styles.PageSubtitle}>
          Feel free to tailor and customize this product description to match the specific features and benefits of your Online Payroll System.
          </Typography>
        </div>
        </div>
        <div style={{marginTop:"20px",marginBottom:"10px"}}>
        <ProductsPageIntro />
      </div>
    </Container>
  );
}

export default PAYROLL;