// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services_web__xhtPj {
    background-color: #ffffff;
    height: 341px;
    width: 379px;
  }
  
 .services_overlap__JzwFL {
    height: 337px;
    left: 39px;
    position: relative;
    top: 2px;
    width: 339px;
  }
  
 .services_overlapGroup__SI1O\\+ {
    height: 337px;
    left: 0;
    position: absolute;
    top: 0;
    width: 339px;
  }
  
.services_vector__GpmQU {
    height: 337px;
    left: 111px;
    position: absolute;
    top: 0;
    width: 228px;
  }
  
.services_textWrapper__0-aQX {
    color: #000000;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 3px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 108px;
  }
  
.services_websiteDesigning__IYnj0{
    color: #000000;
    font-family: "Poppins-Light", Helvetica;
    font-size: 14px;
    font-weight: 300;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 170px;
  }
  
.services_fluentWorld__8Bav- {
    height: 51px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 26px;
    width: 51px;
  }
`, "",{"version":3,"sources":["webpack://./src/components/home/services.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,YAAY;EACd;;CAED;IACG,aAAa;IACb,UAAU;IACV,kBAAkB;IAClB,QAAQ;IACR,YAAY;EACd;;CAED;IACG,aAAa;IACb,OAAO;IACP,kBAAkB;IAClB,MAAM;IACN,YAAY;EACd;;AAEF;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,YAAY;EACd;;AAEF;IACI,cAAc;IACd,wCAAwC;IACxC,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;EACZ;;AAEF;IACI,cAAc;IACd,uCAAuC;IACvC,eAAe;IACf,gBAAgB;IAChB,OAAO;IACP,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;EACZ;;AAEF;IACI,YAAY;IACZ,OAAO;IACP,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;IACT,WAAW;EACb","sourcesContent":[".web {\r\n    background-color: #ffffff;\r\n    height: 341px;\r\n    width: 379px;\r\n  }\r\n  \r\n .overlap {\r\n    height: 337px;\r\n    left: 39px;\r\n    position: relative;\r\n    top: 2px;\r\n    width: 339px;\r\n  }\r\n  \r\n .overlapGroup {\r\n    height: 337px;\r\n    left: 0;\r\n    position: absolute;\r\n    top: 0;\r\n    width: 339px;\r\n  }\r\n  \r\n.vector {\r\n    height: 337px;\r\n    left: 111px;\r\n    position: absolute;\r\n    top: 0;\r\n    width: 228px;\r\n  }\r\n  \r\n.textWrapper {\r\n    color: #000000;\r\n    font-family: \"Poppins-Medium\", Helvetica;\r\n    font-size: 18px;\r\n    font-weight: 500;\r\n    left: 3px;\r\n    letter-spacing: 0;\r\n    line-height: normal;\r\n    position: absolute;\r\n    top: 108px;\r\n  }\r\n  \r\n.websiteDesigning{\r\n    color: #000000;\r\n    font-family: \"Poppins-Light\", Helvetica;\r\n    font-size: 14px;\r\n    font-weight: 300;\r\n    left: 0;\r\n    letter-spacing: 0;\r\n    line-height: normal;\r\n    position: absolute;\r\n    top: 170px;\r\n  }\r\n  \r\n.fluentWorld {\r\n    height: 51px;\r\n    left: 0;\r\n    object-fit: cover;\r\n    position: absolute;\r\n    top: 26px;\r\n    width: 51px;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"web": `services_web__xhtPj`,
	"overlap": `services_overlap__JzwFL`,
	"overlapGroup": `services_overlapGroup__SI1O+`,
	"vector": `services_vector__GpmQU`,
	"textWrapper": `services_textWrapper__0-aQX`,
	"websiteDesigning": `services_websiteDesigning__IYnj0`,
	"fluentWorld": `services_fluentWorld__8Bav-`
};
export default ___CSS_LOADER_EXPORT___;
