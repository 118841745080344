import React from 'react';
import Styles from "./vision.module.css";
import { Typography } from '@mui/material';

const VisionMission = () => {
  return (
    <div className={Styles.visionMissionContainer}>
      <div className={Styles.visionBox}>
        <div className={Styles.topic}>
          <div className={Styles.topicUnderline}></div>
          <Typography className={Styles.subTopic}>Our Vision</Typography>
        </div>
        <Typography className={Styles.description}>
          Data and maintain confidentiality. Gain valuable insights into your payroll data with our robust reporting and analytics tools. Analyze payroll trends, expenses, and employee costs to make informed decisions for your business. Transform your payroll operations with our Online Payroll System. Simplify payroll processes, reduce administrative burdens, and focus on what matters most—growing your business. Join the ranks of satisfied users and experience effortless payroll management today!
        </Typography>
      </div>
    </div>
  );
};

export default VisionMission;