import React from 'react'
import ProductsPageIntro from "./productsPageIntro";
import { Button, Container, Grid, Typography } from '@mui/material';
import Styles from './productsIntro.module.css';
import image2 from '../../assets/images/productImages/Rectangleqqq 470.png';

const HR = () => {

  return (
    <Container>
      <div className={Styles.Page}>
        <div className={Styles.PageContainer}>
          <Typography className={Styles.PageTitle}>HRM</Typography>
          <img className={Styles.PageImage} src={image2} alt="E-Com" />
          <Typography className={Styles.PageDescription}>
          Welcome to the N-ACH human resource management System, where you gain a comprehensive 360-degree perspective of your staff!
          </Typography>
          <Typography className={Styles.PageSubtitle}>
          Welcome to our cutting-edge Online Human Resource System—an intelligent, user-friendly platform designed to streamline HR processes, empower your teams, and elevate your organization.
          <br /><br />
          Step into the future of HR management with our Online Human Resource System. Simplify processes, boost productivity, and nurture a thriving workplace culture. Join our community of satisfied users and transform your HR operations today!
          <br /><br />
Feel free to customize and adapt this product description to match the specific features and benefits of your Online Human Resource System.

          </Typography>
          <Typography className={Styles.PageKeyFeatures}>KEY FEATURES</Typography>
        </div>
      </div>  

        <div className={Styles.descriptionContainer}>
          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Centralized HR Management:</Typography>
              <Typography className={Styles.descriptionText}>
              Experience the convenience of managing all HR functions from a centralized platform. Our system seamlessly integrates employee data, attendance records, performance evaluations, and more in one secure location.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Employee Self-Service Portal:</Typography>
              <Typography className={Styles.descriptionText}>
              Empower your workforce with a self-service portal where they can access their information, request time off, view pay stubs, and update personal details. This enhances communication and engagement while reducing administrative workload.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Recruitment and Applicant Tracking:</Typography>
              <Typography className={Styles.descriptionText}>
              Simplify your hiring process with our intuitive recruitment module. Post job openings, manage applications, and track candidates seamlessly, ensuring a smooth recruitment journey from start to finish.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Performance Management and Reviews:</Typography>
              <Typography className={Styles.descriptionText}>
              Elevate employee performance through our performance management tools. Set objectives, conduct reviews, and provide constructive feedback to drive growth and development within your organization.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Learning and Development:</Typography>
              <Typography className={Styles.descriptionText}>
              Invest in your team's skills and knowledge with our comprehensive learning and development module. Easily create, assign, and track training programs, empowering your workforce to grow and excel.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Customizable Reporting and Analytics:</Typography>
              <Typography className={Styles.descriptionText}>
              Access actionable insights through customizable reports and analytics. Measure key HR metrics, analyze trends, and make data-driven decisions to enhance your HR strategies and operations.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Data Security and Compliance:</Typography>
              <Typography className={Styles.descriptionText}>
              Safeguard sensitive HR data with our robust security measures. Our system complies with industry standards to ensure your data is protected and remains compliant with regulations.
              </Typography>
            </div>
          </div>
        </div>
        <div style={{marginTop:"20px",marginBottom:"10px"}}>
        <ProductsPageIntro />
      </div>
    </Container>
  );
}

export default HR;