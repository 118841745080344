import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Styles from "./ourServices.module.css";
import world from "../../assets/images/home/fluent-mdl2_world.png"
import tools from "../../assets/images/home/ri_tools-fill.png"
import chart from "../../assets/images/home/mdi_chart-line.png"
import { useNavigate } from 'react-router-dom';
import VectorSVG from './vectorSVG';

const services = [
    {id: 1, img: world, link: '/services/web-design', name: 'Web Design', description: 'Website designing is the process of creating visually appealing and functional websites that engage users and effectively convey a message.'},
    {id: 2, img: tools, link: '/services/tailor-made-software-solutions', name: 'Tailor mode software solutions', description: 'Tailor-made software solutions, also known as custom software solutions or bespoke software.'},
    {id: 3, img: chart, link: '/services/bpm', name: 'Business process management', description: 'BPM is a strategic approach that focuses on identifying, analyzing, designing, implementing, monitoring.'},
]

function OurServices() {
    const navigate = useNavigate();

    return (
        <div className={Styles.outerContainer}>
            <Typography className={Styles.topic}>Our Services</Typography>
            <Grid container>
                <Grid container spacing={3} justifyContent="center">
                    {services.map(service => (
                        <Grid item xs={12} sm={6} md={4} key={service.id}>
                            <div className={Styles.serviceBox}>
                                <div className={Styles.imageStyle}>
                                    <img src={service.img} />
                                </div>
                                <div className={Styles.textContainer}> 
                                    <Typography className={Styles.subTopic}>{service.name}</Typography>
                                    <Typography className={Styles.description}>
                                        {service.description}
                                    </Typography>
                                </div>
                                <VectorSVG />
                                
                                <Button onClick={() => navigate(service.link)} variant="contained" color="primary">More Info</Button>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>
    );
}

export default OurServices;
