import React from 'react';
import Styles from "./ceoMsg.module.css";
import image from "../../assets/images/aboutUs/ceo.png";
import { Typography } from '@mui/material';

const CeoMsg = () => {
  return (
    <div className={Styles.container}>
      <img src={image} alt="CEO" className={Styles.image}/>
      <div>
        <Typography className={Styles.topic}>Our CEO's Message</Typography>
        <img src={image} alt="CEO" className={Styles.mobileImage}/>
        <div className={Styles.ceoMsgContainer}>
          <Typography className={Styles.ceo_msg}>
            Welcome to our advanced Customer Relationship Management (CRM) System—an innovative and intuitive platform designed to revolutionize the way you manage customer interactions and enhance your business relationships. Gain a comprehensive understanding of your customers with a centralized database that consolidates all customer information, interactions, and transactions. Access a complete view of customer history to personalize interactions and drive meaningful engagement. Streamline your contact and lead management processes. Capture and organize leads effortlessly, nurturing them through the sales pipeline to conversion. Seamlessly manage contacts and their associated details for targeted communication. Optimize your sales process with our powerful automation tools. Automate repetitive tasks, track deals through the sales pipeline, set reminders, and analyze sales performance to drive revenue growth.
            <br /><br />
            Launch targeted marketing campaigns and monitor their effectiveness directly within the CRM. Integrate email marketing seamlessly, enabling you to manage and track campaigns, measure engagement, and adjust strategies for optimal use this data to make informed business decisions and refine your strategies. Tailments. Our system grows with you, easily scaling to accommodate your business growth. Join the ranks of successful businesses leveraging our CRM System to optimize customer relationships, increase efficiency, and drive business growth. Experience the future of customer engagement—join us today.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CeoMsg;
