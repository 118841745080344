import { Grid, Typography } from '@mui/material';
import React from 'react';
import Styles from "./WhyChooseUs.module.css";
import image1 from "../../assets/images/home/Students2.png";

const WhyChooseUs = () => {
  return (
    <div className={Styles.outerContainer}>
        <Typography className={Styles.topic}>Why Choose Us</Typography>
        <Typography className={Styles.description}>
          Our days are spent constructing sites and strengthening one another. An opportunity to interact, work together, and recognize our clients and coworkers arises with each new project. We're in this together and for the long haul.
        </Typography>
        <Grid container style={{alignContent: 'center'}}>
            <Grid item xs={12} md={6} className={Styles.leftContainer}>
            <div className={Styles.boxWrap}>
              <div className={Styles.box}>
                <ul className={Styles.numberedList}>
                  <li><Typography className={Styles.listItem}>Transparency</Typography></li>
                  <li><Typography className={Styles.listItem}>Reasonable Price</Typography></li>
                  <li><Typography className={Styles.listItem}>Latest Technology</Typography></li>
                  <li><Typography className={Styles.listItem}>Zero Distance Ideas</Typography></li>
                  <li><Typography className={Styles.listItem}>Value Added Features</Typography></li>
                  <li><Typography className={Styles.listItem}>24x7 Customer Support</Typography></li>
                </ul>
              </div>
            </div>
          </Grid>
            <Grid item xs={12} md={6} className={Styles.imageGrid}>
            <img src={image1} alt="Men" />
            </Grid>
        </Grid>
    </div>
  );
}

export default WhyChooseUs;
