import React from 'react'
import {Container, Typography } from '@mui/material';
import Styles from './servicesIntro.module.css';
import image2 from '../../assets/images/productImages/Rectanglewe 470.png';
import ServiceIntro from './serviceIntro';

const Trailor = () => {

    return (
      <Container>
        <div className={Styles.Page}>
          <div className={Styles.PageContainer}>
            <Typography className={Styles.PageTitle}>Tailor mode software solutions</Typography>
            <img className={Styles.PageImage} src={image2} alt="E-Com" />
            <Typography className={Styles.PageDescription}>
            Tailor Mode Software Solutions: Crafting Your Business Excellence
            </Typography>
            <Typography className={Styles.PageSubtitle}>
            Step into a world of tailored solutions with Tailor Mode Software. Our software solutions are intricately designed to fit your business needs like a perfectly tailored suit. We understand that no two businesses are alike, and our approach revolves around creating bespoke software solutions that align seamlessly with your unique requirements and goals.
            </Typography>
          </div>
        </div>  

          <div className={Styles.descriptionContainer}>
            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionText}>
                With Tailor Mode Software, customization is at the forefront. We offer a suite of software tools and applications that can be precisely configured and adapted to match your workflow, processes, and industry demands. Whether it's optimizing operations, streamlining communications, or enhancing productivity, our software solutions are built to empower your business at every level.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionText}>
                Our experienced team of software developers and engineers collaborate closely with you, ensuring a deep understanding of your business objectives. This collaboration allows us to meticulously craft software that not only meets but surpasses your expectations. We believe in staying ahead of the technological curve, integrating the latest advancements to future-proof your solutions and keep you ahead of the competition.
                </Typography>
              </div>
            </div>

            <div className={Styles.descriptionItem}>
              <span className={Styles.bullet}></span>
              <div>
                <Typography className={Styles.descriptionText}>
                Experience the epitome of customization and efficiency with Tailor Mode Software Solutions. Let's elevate your business to new heights, one tailored solution at a time.
                </Typography>
              </div>
            </div>

          </div>
          <div style={{marginTop:"20px",marginBottom:"10px"}}>
          <ServiceIntro />
        </div>
      </Container>
    );
  }

export default Trailor;