import React from 'react'
import ProductsPageIntro from "./productsPageIntro";
import { Button, Container, Grid, Typography } from '@mui/material';
import Styles from './productsIntro.module.css';
import image2 from '../../assets/images/productImages/Rectangleqwezxd 470.png';

const POS = () => {

  return (
    <Container>
      <div className={Styles.Page}>
        <div className={Styles.PageContainer}>
          <Typography className={Styles.PageTitle}>POS</Typography>
          <img className={Styles.PageImage} src={image2} alt="E-Com" />
          <Typography className={Styles.PageDescription}>
            Welcome to N-ACH Online and Mobile Point Of Sale System !
          </Typography>
          <Typography className={Styles.PageSubtitle}>
          N-ACH state-of-the-art Online Point of Sale (POS) System—an intelligent, reliable, and efficient solution designed to revolutionize the way you manage your business transactions.
          </Typography>
          <Typography className={Styles.PageKeyFeatures}>KEY FEATURES</Typography>
        </div>
      </div>  

        <div className={Styles.descriptionContainer}>
          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Seamless Transactions</Typography>
              <Typography className={Styles.descriptionText}>
              Process sales swiftly and efficiently with our intuitive POS interface. From ringing up products to accepting various payment methods, our system ensures smooth and seamless transactions.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Real-Time Inventory Management</Typography>
              <Typography className={Styles.descriptionText}>
              Stay on top of your stock levels in real-time. Our POS system is integrated with an advanced inventory management feature, helping you optimize stock, reduce overstocking, and streamline orders.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Customizable for Your Business</Typography>
              <Typography className={Styles.descriptionText}>
              Tailor the system to suit your unique business needs. Customize products, prices, and tax settings to align with your specific industry and operational requirements.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Cloud-Based Accessibility</Typography>
              <Typography className={Styles.descriptionText}>
              Access your POS system from anywhere, anytime. Our cloud-based solution ensures that you can manage your business on-the-go, whether you're at the store, at home, or on vacation.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Insightful Reporting and Analytics</Typography>
              <Typography className={Styles.descriptionText}>
              Gain valuable insights into your sales, customer behavior, and product performance through comprehensive reporting tools. Make informed decisions to grow your business strategically.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Security and Compliance</Typography>
              <Typography className={Styles.descriptionText}>
              Your data's security is our top priority. Our system adheres to industry-leading security standards, providing a secure and compliant environment for all your transactions.
              </Typography>
            </div>
          </div>

          <div className={Styles.descriptionItem}>
            <span className={Styles.bullet}></span>
            <div>
              <Typography className={Styles.descriptionTopic}>Customer Relationship Management (CRM)</Typography>
              <Typography className={Styles.descriptionText}>
              Build stronger customer relationships by capturing customer data and preferences. Use this information to provide personalized services and drive repeat business.
              </Typography>
            </div>
          </div>

        </div>
        <div style={{marginTop:"20px",marginBottom:"10px"}}>
        <ProductsPageIntro />
      </div>
    </Container>
  );
}

export default POS;