import React from 'react';

const VectorSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="228" height="337" viewBox="0 0 228 337" fill="none">
    <path d="M32.9864 153.182C-26.7552 94.9727 8.09409 26.8068 32.9864 0H228V337H14.2105C45.3615 299.981 92.728 211.391 32.9864 153.182Z" fill="url(#paint0_linear_85_457)"/>
    <defs>
      <linearGradient id="paint0_linear_85_457" x1="114" y1="0" x2="114" y2="337" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDFF9B" stop-opacity="1"/>
        <stop offset="1" stop-color="#FFBFBF" stop-opacity="0.1"/>
      </linearGradient>
    </defs>
  </svg>
);

export default VectorSVG;
