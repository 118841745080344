// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ourStory_outerContainer__\\+z4h5 {
    margin-left: 4%;
    margin-right: 4%;
}

.ourStory_topic__tK6\\+G {
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.ourStory_description__BxH0r {
    width: 100%;
    font-size: 22px;
    text-align: justify;
    margin-top: 2.5rem;
    margin-left: 8%;
}

.ourStory_imageGrid__nv9g9 img {
    max-width: 100%; 
    height: auto; 
    margin-left: 4%;
    margin-top: 4%;
}
@media (max-width: 768px) {
    .ourStory_description__BxH0r {
        width: 100%;
        margin-left: 0; 
    }
}`, "",{"version":3,"sources":["webpack://./src/components/home/ourStory.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,eAAe;IACf,cAAc;AAClB;AACA;IACI;QACI,WAAW;QACX,cAAc;IAClB;AACJ","sourcesContent":[".outerContainer {\r\n    margin-left: 4%;\r\n    margin-right: 4%;\r\n}\r\n\r\n.topic {\r\n    font-size: 3rem;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    text-align: center;\r\n    margin: 0;\r\n}\r\n\r\n.description {\r\n    width: 100%;\r\n    font-size: 22px;\r\n    text-align: justify;\r\n    margin-top: 2.5rem;\r\n    margin-left: 8%;\r\n}\r\n\r\n.imageGrid img {\r\n    max-width: 100%; \r\n    height: auto; \r\n    margin-left: 4%;\r\n    margin-top: 4%;\r\n}\r\n@media (max-width: 768px) {\r\n    .description {\r\n        width: 100%;\r\n        margin-left: 0; \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `ourStory_outerContainer__+z4h5`,
	"topic": `ourStory_topic__tK6+G`,
	"description": `ourStory_description__BxH0r`,
	"imageGrid": `ourStory_imageGrid__nv9g9`
};
export default ___CSS_LOADER_EXPORT___;
