import { Grid, Typography } from '@mui/material';
import React from 'react';
import image1 from "../../assets/images/aboutUs/groupFlag.png";
import Styles from "./ourStory.module.css";

function OurStory() {
    return (
        <div className={Styles.outerContainer}>
            <Grid container>
                <Grid item xs={12} className={Styles.topicContainer}>
                    <Typography className={Styles.topic}>Our Story</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={image1} alt="Men" className={Styles.image} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography className={Styles.description}>
                        Every business has its Need For Achievement. It could be to increase market shares, expand operations, or even increase revenue. That's where N-ACH comes in. Our business-oriented approach combined with our technological solutions is aimed to help organizations achieve their digital goals and grow in the VUCA world.<br /><br />
                        We bring environmental sustainability and innovative software solutions to build a meaningful connection with our clients and drive progress along the way.<br /><br />
                        Our development team specializes in BPM (Business Process Management) and uses cutting-edge software solutions to provide a unique and fulfilling experience to our clients.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default OurStory;

